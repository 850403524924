// Generated by Framer (1cc6588)

import { addFonts, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["PhyX6OnNL"];

const serializationHash = "framer-ep4QS"

const variantClassNames = {PhyX6OnNL: "framer-v-q7vs8"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "PhyX6OnNL", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-ep4QS", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-q7vs8", className)} data-framer-name={"cardiology"} layoutDependency={layoutDependency} layoutId={"PhyX6OnNL"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-fa890ccc-36c5-4a84-ba7f-9f36503015e7, rgb(234, 242, 244))", borderBottomLeftRadius: 64, borderBottomRightRadius: 64, borderTopLeftRadius: 64, borderTopRightRadius: 64, ...style}}><SVG className={"framer-1dg9l9"} data-framer-name={"cardiology"} fill={"black"} intrinsicHeight={42} intrinsicWidth={42} layoutDependency={layoutDependency} layoutId={"hjICE2hHF"} svg={"<svg width=\"42\" height=\"42\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M19.6 34.303 8.043 23.515a9.878 9.878 0 0 1-.761-.793h5.564c1.445 0 2.75-.87 3.306-2.206l.671-1.612 3.153 7.003c.243.543.774.895 1.368.901a1.551 1.551 0 0 0 1.407-.85l2.712-5.43.108.218a3.583 3.583 0 0 0 3.204 1.982h6.683a9.873 9.873 0 0 1-.761.793L23.142 34.303a2.596 2.596 0 0 1-3.543 0Zm17.611-14.65H28.77a.521.521 0 0 1-.46-.282l-1.484-2.961a1.545 1.545 0 0 0-1.375-.85c-.582 0-1.113.326-1.375.85l-2.648 5.295-3.26-7.284a1.529 1.529 0 0 0-1.42-.901 1.54 1.54 0 0 0-1.395.946l-2.033 4.88a.51.51 0 0 1-.473.313H6.023c-.166 0-.32.025-.467.07A9.584 9.584 0 0 1 5 16.512v-.37a9.14 9.14 0 0 1 15.604-6.466l.767.768.767-.768a9.14 9.14 0 0 1 15.604 6.465v.371a9.44 9.44 0 0 1-.53 3.14Z\" fill=\"#2C7B8E\"/></svg>"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-ep4QS [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ep4QS .framer-1yx23pr { display: block; }", ".framer-ep4QS .framer-q7vs8 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: 64px; justify-content: center; overflow: hidden; padding: 32px 32px 32px 32px; position: relative; width: 64px; will-change: var(--framer-will-change-override, transform); }", ".framer-ep4QS .framer-1dg9l9 { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 42px); position: relative; width: 42px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-ep4QS .framer-q7vs8 { gap: 0px; } .framer-ep4QS .framer-q7vs8 > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-ep4QS .framer-q7vs8 > :first-child { margin-left: 0px; } .framer-ep4QS .framer-q7vs8 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 64
 * @framerIntrinsicWidth 64
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 */
const Framerf2WO83DL4: React.ComponentType<Props> = withCSS(Component, css, "framer-ep4QS") as typeof Component;
export default Framerf2WO83DL4;

Framerf2WO83DL4.displayName = "medical-icon";

Framerf2WO83DL4.defaultProps = {height: 64, width: 64};

addFonts(Framerf2WO83DL4, [])